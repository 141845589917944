<script lang="ts" setup>
import { useModal } from 'vue-final-modal'
import ModalDocumentType from '@register/components/Review/Modal/ModalDocumentType.vue'
import { DocumentType } from '@register'
import type { IImportDocumentToReview } from '@workflow'
import { toast } from 'vue3-toastify'
import type { FetchError } from 'ofetch'
import { useQueryClient } from '@tanstack/vue-query'

const props = defineProps<{ document: IImportDocumentToReview }>()

const companyId = getCurrentDivisionId()

const documentType = computed(
  () => props.document.documentType || DocumentType.UNKNOWN,
)
const documentId = computed(() => props.document.id)

const tooltip = computed(() => `Change type ${title(documentType.value)}`)

const { updateDocument } = useApiImportDocumentsModify(
  companyId,
  documentId.value,
)

const queryClient = useQueryClient()
const { open, close } = useModal({
  component: ModalDocumentType,
  attrs: {
    onClose() {
      close()
    },
    onConfirm(type: DocumentType) {
      updateDocument.mutate(
        { type },
        {
          onSuccess: () => {
            toast.success('Document type updated')
            queryClient.invalidateQueries({
              queryKey: ['getMeImportDocuments', companyId, 'toReview'],
            })
          },
          onError: (error: FetchError) => {
            toast.error(error.data?.detail || 'Error updating document type')
          },
        },
      )
      close()
    },
    // computed property will be automatically unwrapped when passed as prop
    defaultType: documentType as any as DocumentType,
    documentId: documentId.value,
  },
})
</script>
<template>
  <VTooltip
    class="inline-flex max-w-max"
    placement="top-start"
    :delay="10"
    :distance="12"
    :auto-hide="true"
  >
    <Button
      class="border-gray-750 hover:border-primary hover:bg-primary/5 hover:text-primary focus:border-primary focus:ring-primary/20 rounded-xl border bg-gray-800 px-2.5 py-1 text-xs text-white focus:text-black focus:ring"
      @click="() => open()"
    >
      <span class="truncate">{{ title(documentType) }}</span>
    </Button>

    <template #popper>
      <div class="text-xs">{{ tooltip }}</div>
    </template>
  </VTooltip>
</template>
